export const useSlick = () => {
  const slickPhotoList = (selector) => {
    $(selector).slick({
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'ease-out',
      arrows: true,
      swipe: true,
      swipeToSlide: true,
      touchThreshold: 10,
    })
  }

  const slickResponsive = (selector) => {
    $(selector).slick({
      arrows: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      cssEase: 'ease-out',
      swipeToSlide: true,
      touchThreshold: 10,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    })
  }

  return {
    slickPhotoList,
    slickResponsive,
  }
}
